<template>
  <div>
    <div class="content">
      <div class="SearchBar">
        <!-- <el-button  @click="closeadd" size="mini" class="backbtn">返回</el-button> -->
         <!-- <el-form
        ref="search"
        class="searchForm"
        :model="paramsData"
        label-width="auto"
        :inline="true"
      >
        <el-form-item label="时间段" prop="equipmentStatus" label-width="76px">
          <el-date-picker
            @change="search"
            size="mini"
            style="width:330px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.userId"
            placeholder="请选择巡线员"
            @change="search"
          >
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="完成情况" prop="state" style="margin-left: 14px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.state"
            placeholder="请选择完成情况"
            @change="search"
          >
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
                   :label="item.name"
            :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus" >
          <el-input
          clearable
          size="mini"
            placeholder="请输入名称/型号/地址"
            v-model="paramsData.searchText"
            style="width:300px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="" label-width="0">
        <el-button
          size="mini"
          type="primary"
          @click="search"
          style="margin-left:10px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resize"
          >重置</el-button
        >
         </el-form-item>
        </el-form> 365-->

            <el-table
              ref="tableData"
              :data="tableData"
              border
              style="margin:0 20px;width:97.5%"
              height="calc(100vh - 156px)"
              :stripe="true"
              :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                textAlign:'center',
                background: '#f8f8f9',
              }"
            >
             <el-table-column
          type="index"
          label="序号"
          align="left"
          width="50"
          >
          </el-table-column>
              <el-table-column prop="taskCode" label="任务编号" align="left" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="networkName" label="巡检区域" align="left" show-overflow-tooltip>
                
              </el-table-column>
              <el-table-column prop="executorPeopleName" label="巡线员" align="left" show-overflow-tooltip>
               
              </el-table-column>
              <el-table-column prop="scheduleStartTime" label="开始时间" align="left"  width="160">
              </el-table-column>
              <el-table-column prop="scheduleEndTime" label="结束时间" align="left"  width="160">
                <template slot-scope="scope">
                {{scope.row.trackStatus !=1 ? scope.row.scheduleEndTime : '--'}} 
                </template>
              </el-table-column>
              <el-table-column prop="itemName" label="巡线总时长" align="left" width="170">
                 <template slot-scope="scope">
                <!-- {{(scope.row.scheduleTime/60).toFixed(2)}} <span>分钟</span> -->
                {{parseInt(scope.row.scheduleTime/60/60%24)}} <span>小时</span>
                {{parseInt(scope.row.scheduleTime/60%60)}} <span>分钟</span>
                {{parseInt(scope.row.scheduleTime%60)}} <span>秒</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="lineLength" width="140" label="巡线总里程(km)/管线总长度(km)">
                 <template slot-scope="{row}">
                    <div class="examine">{{(row.scheduleDistance/1000).toFixed(2)}}/
                      <span style="color:#2C9EF7"  @click="seeKm(row)">{{(row.jsL&&row.jsL!=-1)?row.jsL:(row.jsL == 0 ? 0 :'查看')}}</span>

                      </div>
                  </template>
              </el-table-column>
              <!-- <el-table-column prop="itemName" label="巡线总里程（km）/管线总长度（km）" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{(scope.row.scheduleDistance/1000).toFixed(2)}} / {{(scope.row.lineLength/1000).toFixed(2)}}
                </template>
              </el-table-column> -->
              <el-table-column prop="itemName" label="已打卡点/应打卡点" width="80" align="left" show-overflow-tooltip>
                 <template slot-scope="scope">
                 {{scope.row.isPushNumber}}/{{scope.row.allPush}}
                </template>
              </el-table-column>
              <el-table-column prop="itemName" label="完成情况" align="left" width="80" show-overflow-tooltip>
                 <template slot-scope="{ row }">
                  <el-tag v-if="row.trackStatus == '2'" type='success' size="mini">
                       已完成
                    </el-tag>  
                    <el-tag v-else size="mini" style="color: #E83434;border: 1px solid #F5A7A7;background: #FFF4EB;">
                        未完成
                    </el-tag> 
                    

                 </template>
              </el-table-column>
              <el-table-column prop="eventNumber" label="事件上报数目"  width="100" align="left" show-overflow-tooltip>
                <template slot-scope="{ row }">
                  <div style="color: #1082ff; cursor: pointer" v-if="row.eventNumber > 0" @click="appear(row)">{{row.eventNumber}}</div>
                  <div v-else>{{row.eventNumber}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="itemName" label="操作" align="left" width="260px">
                <template slot-scope="{ row }">
                    <el-button
                        type="primary"
                        size="mini"
                        v-if="$anthButtons.getButtonAuth('lookResult')" 
                        @click="IsOpenFun(row)"
                        >查看轨迹</el-button>
                    <el-button
                        type="primary"
                        size="mini"
                        v-if="row.trackStatus != '2' && $anthButtons.getButtonAuth('completeResult')"
                        @click="editStatus(row)"
                        >设为完成</el-button>
                    </template>
              </el-table-column>
            </el-table>
            <div style="text-align: left">
            <el-pagination
              @size-change="handleSizeChange"
              background
              layout="total,sizes,prev, pager, next,jumper"
              :total="total"
              :page-sizes="[10,30, 50, 100]"
              :page-size="paramsData.size"
              :current-page="paramsData.current"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
    </div>
      </div>
    </div>
     <map-locus ref="mapLocus"></map-locus>
  </div>
</template>
<script>
import {getTrackWorkPage,getUserList,trackPage,setFinish,getLength} from "../../../RequestPort/maintenance/task";
import {getTrackPath} from "@/RequestPort/Inspection/inspeTrack";
import mapLocus from "./mapLocus.vue";
import { queryFeatrue } from '../../Gis/apis/geo';
import { formatLocation } from '../../Gis/utils/geoTool';
export default {
  components: { mapLocus },
  data() {
    return {
        paramsData:{
          current:1,
          size: 100
        },
        total:0,
        DataPicker:[],
        selectDownArray:[{id:1,name:'未完成'},{id:2,name:'已完成'}],
      form:{},
      tableData: [], //  表格数据
      id:'',
      screcd:'',
      excutorDownArray:[]
    }
  },
  mounted() {
     // 获取巡线员
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    this.id = this.$route.query.id
    this.screcd = this.$route.query.screcd
    this.particulars()
  },
  methods: {
    seeKm(row){
      getLength({id:row.networkId}).then(res => {
        if(res.code == 200) {
          row.jsL = res.data
        }
      })
      // let coverage=row.coverage
      // let network=row.location
      // let pos = []
      // network.forEach(element => {
      //   pos.push(element[0] + ' ' + element[1])
      // })
      // pos.push(pos[0])
      // let posStr = pos.join(',')
      // let groupLayer = this.$store.getters.groupLayer
      // let group = []
      // if (groupLayer && groupLayer.length > 0) {
      //   groupLayer.forEach(item => {
      //     group.push(item.id)
      //   })
      // }

      
      
      // let layersVue1 = coverage
      // let params = {
      //   SERVICE: 'wfs',
      //   VERSION: '1.0.0',
      //   REQUEST: 'GetFeature',
      //   typeName: 'linqing:view_all_line_q',
      //   outputFormat: 'application/json',
      //   viewparams: `netid:${row.networkId}`,
      //    CQL_FILTER:
      //       "coverage in (" + layersVue1 + ") and INTERSECTS(location,POLYGON((" + posStr + ")))"
      //   }
      //   if (!layersVue1) {
      //    params.CQL_FILTER = '1=2'
      //   }  
      //    const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });      
      // queryFeatrue(params).then(res => {//调取管线
      //    loading.close();
       
      //       // this.total = res.totalFeatures
      //       let f = res.features
      //       let line=[]
      //         f.forEach(item => {
      //           let location=formatLocation(item.properties.location_str,'LineString')               
      //               for(let i=1;i<location.length;i++){                 
      //                 let sub=[Number(location[i-1][0]),Number(location[i-1][1])]
      //                 let sub1=[Number(location[i][0]),Number(location[i][1])]                
      //                 line.push([sub1,sub])
      //               }               
      //       })

      //         let py=network
      //       // py.push(py[0])//首尾相接
      //         let allLength=0
      //         line.forEach(item => {//计算长度
      //             var line1 = turf.lineString(item);
      //             var line2 = turf.lineString(py);
      //             var intersects = turf.lineIntersect(line1, line2);  
      //             if(intersects.features.length<1){//没相交是面内
      //                 let pn = turf.polygon([py]);                 
      //                 let isHave=turf.booleanContains(pn, line1);
      //                 if(isHave){                   
      //                     let length = turf.length(line1, {units: 'kilometers'});
      //                     allLength=allLength+length
      //                 }                
      //             }else if(intersects.features.length>0){//一条线相交了两次及以上
      //               var line = turf.lineString(item);
      //               var splitter = turf.lineString(py);
      //               var splitline = turf.lineSplit(line, splitter);     
      //               splitline.features.forEach(fea => {     
      //                 let feaLine=  fea.geometry.coordinates
      //                 let pn = turf.polygon([py]);
      //                 let pl = turf.lineString(feaLine);
      //                 let isHave=turf.booleanContains(pn, pl);
      //                 if(isHave){
      //                   let l=turf.lineString(feaLine)
      //                   let length = turf.length(l, {units: 'kilometers'});
      //                   allLength=allLength+length
      //                 }
      //               }); 
      //             }
      //         })
        
      
      //    allLength>0?row.jsL=allLength.toFixed(3):row.jsL='0'
          
      // })

    },
    /**
     * 上报事件
     */
    appear(row){
      // row:JSON.stringify(row)
      this.$router.push({ path: '/home/incident', query: {id:row.eventId } })
    },
    /**
     * 巡检轨迹
     */
    IsOpenFun(row){
      // 五公里
      getTrackPath({id:row.id,precise:5000}).then(res => {
        this.$refs.mapLocus.open(res.data)
      })
    },
    editStatus(row){
        setFinish(row.id).then(res=>{
            this.$message.success(res.msg)
            this.particulars(1);
        })
    },
    search(){
        if(this.DataPicker && this.DataPicker.length>0){
            this.paramsData.startDate = this.DataPicker[0];
            this.paramsData.endDate = this.DataPicker[1];
        }else{
            this.paramsData.startDate = '';
            this.paramsData.endDate = '';
        }
        this.particulars(1)
    },
    resize(){
      this.paramsData.startDate = '';
      this.paramsData.endDate = '';
      this.particulars(1)
    },
    //返回
    closeadd() {
      this.$router.push({ path: '/home/task', query: { first: 0, screcd: this.screcd } })
    },
    particulars(val) {
      if(val){
        this.paramsData.current = 1
      }
      this.paramsData.trackIds = this.id 
        trackPage(this.paramsData).then(res =>{
          res.data.records.forEach(item => {
            item.jsL='-1'
          });
            this.tableData = res.data.records
            this.total = res.data.total
        })
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.particulars();
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.particulars(1);
    },
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #f3f4f8;;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh );
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.backbtn{
  margin:20px 0px 20px 20px;
  // width: 82px;
  // height: 36px;
}
.row{
  margin-left: 10px;
}
.row2{
  margin-left: 10px;
  margin-top: 10px;
}
.titleDiv{
  margin: 26px 0px 0px 30px;
}
.el-pagination{
        margin: 15px 20px 15px;
  }
</style>
